import router from "@/router";
import cookie from 'vue-cookie';
/** cookie键名枚举 */
const COOKIEKEYS = {
	/** 登录时间戳键名 */
	LOGINSTAMP: 'LOGINSTAMP'
};

/**
 * 获取本地token
 */
const getLocalToken = () => {
	if (!cookie.get(COOKIEKEYS.LOGINSTAMP)){
		removeLocalToken();
	}
	return localStorage.getItem('token');
}

/**
 * 设置本地token
 */
const setLocalToken = (token) => {
	cookie.set(COOKIEKEYS.LOGINSTAMP, Date.now());
	localStorage.setItem('token',  token);
}

/**
 * 清除本地token
 */
const removeLocalToken = () => {
	localStorage.removeItem('token');
}

/**
 * 设置当前窗口merberId
 */
const setWindowMerberId = (memberId) => {
	sessionStorage.setItem('memberId', memberId);
}

/**
 * 获取当前窗口merberId
 */
const getWindowMerberId = () => {
	return sessionStorage.getItem('memberId');
}

/**
 * 设置当前窗口userphone
 */
const setWindowUserphone = (userphone) => {
	sessionStorage.setItem('userphone', userphone);
}

/**
 * 获取当前窗口userphone
 */
const getWindowUserphone = () => {
	return sessionStorage.getItem('userphone');
}

/**
 * 清除当前窗口token 并且进入登录页带上redirect参数  在窗口token存在但不是有效token 或者 窗口token失效时 执行
 */
const removeTokenAndRedirect = () => {
	// sessionStorage.removeItem('token');
	removeLocalToken();
	let { redirect, ...querys } = router.currentRoute.query;
	let { href } = router.resolve({
		path: router.currentRoute.path,
		query: querys
	});
	router.push({
		path: "/",
		query: {
			rendererStamp: Date.now(),
			...router.currentRoute.path !== '/' ? { redirect: href } : {},
		},
	})
	
}

export {
	getLocalToken,
	setWindowMerberId,
	getWindowMerberId,
	setWindowUserphone,
	getWindowUserphone,
	setLocalToken,
	removeLocalToken,
	removeTokenAndRedirect,
}