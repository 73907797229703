<template>
	<div class="enter-verification-code">
		<template v-if="showTitle">
			<div class="enter-verification--title">输入手机验证码</div>
			<p>请输入发送至 {{ regionType }} {{ maskedPhoneNumber }} 的6位验证码，有效时间 5分钟。</p>
		</template>
        <div class="verification-code__wrapper">
			<el-input
					v-for="(code, index) in verificationCodes"
					:key="index"
					v-model="verificationCodes[index]"
					type="text"
					maxlength="1"
					@input="handleInput(index)"
					@focus="handleFocus"
					@keyup.native="onKeyUp(index, $event)"
					ref="inputs"
				></el-input>
			
		</div>
		<div :class="['send-verification-code-btn', timer > 0 ? 'is-disabled' : '']" @click="sendVerificationCode">
			{{ timer > 0 ? timer + "s 后重新发送" : "发送验证码" }}
		</div>
    </div>
</template>

<script>
export default {
	inheritAttrs: false,
	model: {
		prop: 'modelValue',
		event: 'input',
	},
	inject: {
		getFormData: {
			from: 'getFormData',
			default: () => {
				return {
					phone: '',
					regionType: '',
				}
			}
		}
	},
	props: {
		showTitle: {
			type: Boolean,
			default: false,
		},
		sendCodeTimeStamp: {
			type: Number,
			default: 0
		}
	},
	data(){
		return {
			verificationCodes: ["", "", "", "", "", ""], // 用户输入的验证码各位数字
			/** 倒计时计时器 */
            timer: 0,
		}
	},
	computed: {
		formData(){
			return this.getFormData() || {};
		},
		phone(){
			return this.formData.phone;
		},
		regionType(){
			return this.formData.regionType || '';
		},
		maskedPhoneNumber() {
            // 将手机号中间四位替换为 * 号
            return this.phone.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
        },
		isCompletedCodes(){
			return this.verificationCodes.every(item => item);
		}
	},
	watch: {
		sendCodeTimeStamp: {
			handler(val){
				if (val){
					console.log('sendCodeTimeStamp', val);
					this.setInterval();
				}
			},
			immediate: true,
		},
		verificationCodes: {
			handler(){
				this.$emit('input', this.verificationCodes.join(''));
			},
			deep: true,
			immediate: false,
		},
		isCompletedCodes: {
			handler(val){
				// if (val){
				// 	this.$emit('input', this.verificationCodes.join(''));
				// } else {
				// 	this.$emit('input', '');
				// }
			},
			immediate: false,
		}
	},
	methods: {
		setInterval(){
			this.timer = 60 // 假设倒计时时间为60秒
			// setTimeout(() => {
			// 	this.timer = 10;
			// }, 0)
			let countdown = setInterval(() => {
				this.timer--;
				if (this.timer < 1) {
					clearInterval(countdown)
				}
			}, 1000)
		},
		sendVerificationCode(){
			if (this.timer > 0) return;
			this.$emit('send');
		},
		handleInput(index) {
            const value = this.verificationCodes[index]
            if (value !== "") {
                // 如果当前输入框的值不为空，则自动切换到下一个输入框
                if (index < this.verificationCodes.length - 1) {
                    this.$nextTick(() => {
                        this.$refs.inputs[index + 1].focus()
                    })
                }
            }
        },
		handleFocus(){
			this.$emit('focus');
		},
        onKeyUp(index, event) {
            if (event.key === "Backspace" && index > 0 && !this.verificationCodes[index]) {
                this.$nextTick(() => {
                    this.$refs.inputs[index - 1].focus()
                })
            }
        },
		validate(){
			return new Promise(async (resolve) => {
				if (this.verificationCodes.some(item => !item)){
					this.$message({
						type: 'warning',
						message: '验证码必须是六位数'
					});
					resolve(false);
				} else {
					resolve(this.verificationCodes.join(''));
				}
			})
		},
		
	}
}
</script>

<style lang="less">
.enter-verification-code{
	&>.enter-verification--title{
		font-size: 18px;
		color: #000;
		font-weight: normal;
		margin: 6px 0 0;
	}
	&>p{
		font-size: 14px;
    	padding: 10px 0;
	}
	&>.verification-code__wrapper{
		display: flex;
		flex-flow: row nowrap;
		column-gap: 5px;
		&>.el-input{
			width: 50px;
		}
	}
	&>.send-verification-code-btn{
		margin: 12px 0 0;
		font-size: 12px;
		color: #3370ff;
		text-align: right;
		cursor: pointer;
		&.is-disabled{
			color: #999999;
			cursor: not-allowed;
		}
		
	}
	&,
	& *{
		line-height: normal;
	}
}
</style>