<template>
    <div id="MLayoutIndex">
        <div class="layout-index-header">
            <xiaoleyun class="xiaoleyun"></xiaoleyun>
            <div class="registration" @click="goRegistration">免费注册</div>
        </div>
        <div class="layout-index-body">
            <router-view class="layout-index-body-outer-box"></router-view>
            <div class="filingInfo">
                <div class="wrapper">
                    <div style="margin-bottom: 5px">Copyright @2022 Labbol All Rights Reserved</div>
                    <div style="margin-bottom: 5px" @click="goPublic">备案号：京ICP备2022014052号-1</div>
                    <div style="display: flex; justify-content: center" @click="goPolice">
                        <img src="../../components//LayoutIndex/assets/images/备案图标.ef881569.png" />
                        <p>京公网安备 11010802040825号</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import xiaoleyun from "../../components/LayoutIndex/assets/images/xiaoleyun.svg"
export default {
    name: "MLayoutIndex",
    components: {
        xiaoleyun,
    },
    provide() {
        return {
            getKeyword: () => this.keyword,
        }
    },
    data() {
        return {
            keyword: "",
        }
    },
    mounted() {
        this.adjustLayoutIndexBodyHeight()
        window.addEventListener("resize", this.adjustLayoutIndexBodyHeight)
    },
    computed: {
        fullPath() {
            return this.$route.fullPath
        },
    },
    methods: {
        goRegistration() {
            this.$router.push({
                name: "Register",
            })
        },
        goPublic() {
            window.open("https://beian.miit.gov.cn/")
        },
        goPolice() {
            window.open("http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802040825")
        },
        /**
         * @description 自动计算layout-index-body高度
         */
        adjustLayoutIndexBodyHeight() {
            this.$nextTick(() => {
                if (!this.$refs.LayoutRef) return
                const layout = this.$refs.LayoutRef
                const headerWrapper = layout.querySelector(".layout-index-header")
                const bodyWrapper = layout.querySelector(".layout-index-body-outer-box")
                if (headerWrapper && bodyWrapper) {
                    const headerHeight = headerWrapper.offsetHeight
                    const layoutHeight = layout.clientHeight
                    bodyWrapper.style.minHeight = `${layoutHeight - headerHeight}px`
                }
            })
        },
    },
}
</script>

<style lang="less">
#MLayoutIndex {
    height: 100%;
    margin: 0 auto;
    z-index: 10;
    overflow: auto;
    & > .layout-index-header {
        z-index: 9999;
        display: flex;
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        background-color: #fff;
        border-bottom: 1px solid #d5d7dc;
        justify-content: space-between;

        & > .xiaoleyun {
            height: 55px;
            width: 125px;
            padding: 10px;
        }
        & > .registration {
            background-color: #2d74f4;
            align-self: stretch;
            color: #fff;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    & > .layout-index-body {
        & > .filingInfo {
            z-index: 9999;
            position: static;
            bottom: 0;
            left: 0;
            background: #0e1421;
            width: 100%;
            padding: 5px;

            & > .wrapper {
                white-space: nowrap;
                text-align: center;
                color: rgb(147, 147, 147);
                font-size: 12px;
            }
        }
    }
}
</style>