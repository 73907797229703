const AdministratorSetting = () => import('./AdministratorSetting');
const EnterpriseInformation = () => import('./EnterpriseInformation');
const SecuritySetting = () => import('./SecuritySetting');
const InvitationCodeManagement = () => import('./InvitationCodeManagement');

export default [
	{
		path: '/EnterpriseInformation',
		name: 'EnterpriseInformation',
		component: EnterpriseInformation,
		meta: {
			title: '企业信息',
			groupName: '企业设置',
			icon: 'EnterpriseInformation'
		}
	},
	{
		path: '/SecuritySetting',
		name: 'SecuritySetting',
		component: SecuritySetting,
		meta: {
			title: '安全设置',
			groupName: '企业设置',
			icon: 'SecuritySetting'
		}
	},
	{
		path: '/AdministratorSetting',
		name: 'AdministratorSetting',
		component: AdministratorSetting,
		meta: {
			title: '管理员设置',
			groupName: '企业设置',
			icon: 'AdministratorSetting'
		}
	},
	{
		path: '/InvitationCodeManagement',
		name: 'InvitationCodeManagement',
		component: InvitationCodeManagement,
		meta: {
			title: '邀请码管理',
			groupName: '企业设置',
			icon: 'AdministratorSetting'
		}
	},
]



