const InvoiceInformation = () => import('./InvoiceInformation');
const InvoiceRecord = () => import('./InvoiceRecord');
const PendingInvoice = () => import('./PendingInvoice');

export default [
	{
		path: '/InvoiceRecord',
		name: 'InvoiceRecord',
		component: InvoiceRecord,
		meta: {
			title: '开票记录',
			groupName: '发票管理',
			icon: 'InvoiceRecord'
		}
	},
	{
		path: '/InvoiceInformation',
		name: 'InvoiceInformation',
		component: InvoiceInformation,
		meta: {
			title: '开票信息',
			groupName: '发票管理',
			icon: 'InvoiceInformation'
		}
	},
	{
		path: '/PendingInvoice',
		name: 'PendingInvoice',
		component: PendingInvoice,
		meta: {
			title: '待开发票',
			groupName: '发票管理',
			icon: 'PendingInvoice'
		}
	},
]


