import Vue from "vue"
import App from "./App.vue"
import router from "./router";
import './router/beforeEach';
import store from "./store"
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
import api from '../src/api/api.js'

Vue.use(ElementUI);
Vue.prototype.api = api;

Vue.config.productionTip = false

import mixin from '@/core/mixin';
Vue.mixin(mixin);

// import ChoiceProduct from "@/components/ChoiceProduct"
// Vue.use(ChoiceProduct);

import core from '@/core/index';
Vue.use(core);
// import '@/core/index';

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount("#app")
