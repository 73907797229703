import VueRouter from "vue-router"
//保存下来push方法 将来我们还会用到原push方法进行路由跳转
let originPush = VueRouter.prototype.push

//接下来我们重写push|replace方法
//第一个参数location参数像原push方法声明跳转的地方 resolve和reject传递成功与失败

VueRouter.prototype.push = function (location, resolve, reject) {
    if (resolve && reject) {
        //如果成功 调用原来的push方法
        originPush.call(this, location, resolve, reject)
    } else {
        originPush.call(
            this,
            location,
            () => {},
            () => {}
        )
    }
}

import store from "@/store"
import router from "./index"
import { GatewayPath, RegisterPath, ChangePasswordPath, LoginPath, IndexPath } from "./index"
import { isElectron, ipcRenderer, isDev } from "@/core/Electron"
import { setLocalToken, getLocalToken, removeLocalToken } from "@/core/token"

//判断用户是否登录
/** 是否为初次加载当前token对应用户信息 */
let firstLoad = true
// 当前环境为electron 本地token存在 且为生产模式 清除token
if (isElectron && getLocalToken() && !isDev) {
    removeLocalToken()
}
// setLocalToken('1212');
let loginPaths = [RegisterPath, LoginPath, ChangePasswordPath]

router.beforeEach(async (to, from, next) => {
    /** query传递的token值 多用于系统或门户之间跳转 */
    let queryToken = to.query.token
    // query传递的token值存在 设置token至本地
    if (queryToken) {
        setLocalToken(queryToken)
    }
    // 当前环境为electron 本地token存在 且为初次加载 直接设置当前窗口token
    else if (isElectron && getLocalToken() && firstLoad && isDev) {
        setLocalToken(getLocalToken())
    }

    let token = getLocalToken()
    // console.log('beforeEach', token, firstLoad, to.path, from.path);
    /** 需要重定向的路由地址 */
    let redirect
    // 网络无连接  来源路由地址不是OfflinePath，此时需要跳转OfflinePath，并传入来源路由地址from.query.redirect或from.fullPath做redirect参数
    // if (!window.navigator.onLine && to.path === OfflinePath && !to.query.redirect && from.path !== OfflinePath){
    //     redirect = encodeURIComponent(from.fullPath);
    //     console.log('网络无连接, redirect=', redirect);
    //     next({
    //         path: OfflinePath,
    //         query: {
    //             redirect,
    //         },
    //         replace: true
    //     });
    // }
    // // 网络无连接  目标路由是OfflinePath，此时需要跳转OfflinePath，并传入目标路由做redirect参数
    // else if (!window.navigator.onLine && to.path !== OfflinePath && from.path === OfflinePath){
    //     next({
    //         path: from.fullPath,
    //         replace: true,
    //     });
    // }
    // else if (!window.navigator.onLine && to.path !== OfflinePath && from.path !== OfflinePath){
    //     redirect = encodeURIComponent(to.fullPath);
    //     console.log('网络无连接, redirect=', redirect);
    //     next({
    //         path: OfflinePath,
    //         query: {
    //             redirect,
    //         },
    //         replace: true
    //     });
    // }
    // else if (!window.navigator.onLine){
    //     next();
    // }
    // // 网络已连接 目标路由地址为OfflinePath，此时需要重定向
    // else if (to.path === OfflinePath){
    //     if (to.query.redirect){
    //         redirect = decodeURIComponent(decodeURIComponent(to.query.redirect));
    //     } else {
    //         redirect = '/';
    //     }
    //     console.log('网络已连接, redirect=', redirect, to.fullPath);
    //     next({
    //         path: redirect,
    //         replace: true
    //     })
    // }
    var userAgent = navigator.userAgent || navigator.vendor || window.opera

    // 使用正则表达式来检查userAgent字符串中是否包含某些关键字
    // 这些关键字通常与移动设备相关
    var mobile = /windows phone|iphone|ipad|ipod|android|blackberry|mini|windows ce|palm/i.test(userAgent.toLowerCase())

    if (mobile) {
        next()
        return
    }
    // 当前窗口token存在
    if (token) {
        if (firstLoad) {
            await Promise.all([store.dispatch("getUserInfo")])
            firstLoad = false
        }
        let redirect
        // 根据query,redirect获取重定向地址
        if (from.query.redirect) {
            redirect = decodeURIComponent(decodeURIComponent(from.query.redirect))
        }
        console.log(redirect, from, to.path, "to.path")
        if (redirect && redirect !== to.path) {
            next({
                path: redirect,
            })
        } else if (loginPaths.includes(to.path)) {
            next({
                path: IndexPath,
            })
        }
        // 登录 直接进入控制台
        else if (IndexPath === to.path && LoginPath === from.path) {
            next({
                path: GatewayPath,
            })
        } else if (IndexPath === to.path) {
            next()
            // next({
            //     path: GatewayPath
            // });
        }
        // //去往登录/注册页/初始页  重定向到控制台/网关
        // if ((loginPaths.includes(to.path) || IndexPath === to.path) && (![...loginPaths, IndexPath].includes(from.path))) {
        //     console.log('token.redirect', from.query, from.query.redirect);
        //     let redirect = GatewayPath;
        //     if (from.query.redirect){
        //         let tempRedirect = decodeURIComponent(decodeURIComponent(from.query.redirect));
        //         if (!loginPaths.includes(to.path) && tempRedirect !== to.path){
        //             redirect = tempRedirect;
        //         }
        //     }
        //     next({
        //         path: redirect,
        //         replace: true
        //     })
        // }
        // else if (to.path === GatewayPath && from.query.redirect && decodeURIComponent(decodeURIComponent(from.query.redirect)) !== GatewayPath){
        //     next({
        //         path: decodeURIComponent(decodeURIComponent(from.query.redirect)),
        //     })
        // }
        else {
            // console.log('token-existed, non-redirect', token, to.path, from.path);
            next()
            if (isElectron && loginPaths.includes(from.path)) {
                ipcRenderer.send("setMainWindow")
            }
        }
    }
    // 当前窗口token不存在
    else {
        if (to.path === GatewayPath) {
            // console.log('to.path === GatewayPath', to.path);
            next({
                path: LoginPath,
                query: {
                    redirect: GatewayPath,
                },
            })
        } else if (IndexPath === to.path) {
            if (from.path === ChangePasswordPath) {
                next({
                    path: LoginPath,
                })
            } else {
                next()
            }
        } else if (loginPaths.includes(to.path)) {
            // console.log('loginPaths.includes(to.path) || IndexPath === to.path', to.path);
            firstLoad = true
            if (isElectron) {
                ipcRenderer.send("setLoginWindow")
            }
            let fromQuery = from.query
            console.log("loginPaths.includes(to.path)")
            if (LoginPath === to.path && fromQuery.redirect && !to.query.redirect) {
                delete fromQuery.rendererStamp
                let location = {
                    // ...from,
                    path: to.path,
                    query: fromQuery,
                }
                console.log("LoginPath", to.path, fromQuery, location)
                next(location)
            } else {
                console.log("!LoginPath", to.path, fromQuery)
                next()
            }
        } else {
            console.log("else", to.path)
            next({
                path: LoginPath,
                query: {
                    redirect: to.fullPath,
                },
            })
        }
    }
})

// window.addEventListener('offline', () => {
//     console.log('网络断开');
//     router.push({
//         path: OfflinePath
//     });
// })
// window.addEventListener('online', () => {
//     /** 网络连接时，需要重定向的路由 */
//     let redirect = router.currentRoute.query.redirect;
//     if (redirect){
//         redirect = decodeURIComponent(decodeURIComponent(redirect));
//     } else {
//         redirect = '/'
//     }
//     console.log('网络连接', redirect);
//     router.push({
//         path: redirect
//     });
// })
