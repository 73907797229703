const MyOrder = () => import('./MyOrder');
const MyProduct = () => import('./MyProduct');
const RenewalManagement = () => import('./RenewalManagement');

export default [
	{
		path: '/MyOrder',
		name: 'MyOrder',
		component: MyOrder,
		meta: {
			title: '我的订单',
			groupName: '订单管理',
			icon: 'MyOrder'
		}
	},
	{
		path: '/RenewalManagement',
		name: 'RenewalManagement',
		component: RenewalManagement,
		meta: {
			title: '续费管理',
			groupName: '订单管理',
			icon: 'RenewalManagement'
		}
	},
	{
		path: '/MyProduct',
		name: 'MyProduct',
		component: MyProduct,
		meta: {
			title: '我的产品',
			groupName: '订单管理',
			icon: 'MyProduct'
		}
	},
]

