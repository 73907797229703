// electron运行环境下不支持Promise.finally 引入core-js来支持Promise.finally写法
import "core-js/features/promise/finally"
// import '@/core/Electron';

import Rsa from "@/utils/rsa";
import TreeSelect from "../components/TreeSelect"
/** 重点↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓ */
// 此处的Vue必须和mainjs中为同一个Vue构造函数；(Vue本质为构造函数，同一个构造函数构造的2个对象，因为参数或先前执行的方法不同，所得到的对象可能存在差异)
// LabbolBasicComponents中有dialogDrag等指令、扩展组件方法、全局组件等，主应用的Vue.use等放在前面，确保其他扩展组件方法同样拥有主应用的mixin等；
// 主应用扩展组件方法则放在后面，确保主应用扩展组件同样拥有所有的mixin等；

import LabbolBasicComponents from "labbol-basic-components";
// import LabbolBasicComponents from "../../../../BasicComponents/src/components/index"
// import LabbolBasicComponents from "../../../../../labbol-basic-components/labbol-basic-components";


import ChoiceProduct from "@/components/ChoiceProduct";

export default {
    install(Vue){
        Vue.prototype.Rsa = Rsa;
        Vue.component("TreeSelect", TreeSelect);
        Vue.use(LabbolBasicComponents);
        Vue.use(ChoiceProduct);
    }
}

import {
    useForm, usePageWrapper, useDrawer, useDialog, useTable, PATTERNTYPESENUM, PATTERNSENUM, SvgComponentNames,
    choicePersonnelByOrgRole, choicePersonnelByRoleGroup,
    LoginPath, LoginName, Login,
    RegisterPath, RegisterName, Register,
    ChangePasswordPath, ChangePasswordName, ChangePassword,
    ToolbarUser, ToolbarSubscribeApps,ToolbarFeedback
} from "labbol-basic-components";
// import {
//     useForm, usePageWrapper, useDrawer, useDialog, useTable, PATTERNTYPESENUM, PATTERNSENUM, SvgComponentNames,
//     choicePersonnelByOrgRole, choicePersonnelByRoleGroup,
//     LoginPath, LoginName, Login,
//     RegisterPath, RegisterName, Register,
//     ChangePasswordPath, ChangePasswordName, ChangePassword,
//     ToolbarUser, ToolbarSubscribeApps,
// } from '../../../../BasicComponents/src/components/index';

// import {
//     useForm, usePageWrapper, useDrawer, useDialog, useTable, PATTERNTYPESENUM, PATTERNSENUM, SvgComponentNames,
//     choicePersonnelByOrgRole, choicePersonnelByRoleGroup,
//     LoginPath, LoginName, Login,
//     RegisterPath, RegisterName, Register,
//     ChangePasswordPath, ChangePasswordName, ChangePassword,
//     ToolbarUser, ToolbarSubscribeApps,
// } from '../../../../../labbol-basic-components/labbol-basic-components';
export {
    useForm, usePageWrapper, useDrawer, useDialog, useTable, PATTERNTYPESENUM, PATTERNSENUM, SvgComponentNames,
    choicePersonnelByOrgRole, choicePersonnelByRoleGroup,
    LoginPath, LoginName, Login,
    RegisterPath, RegisterName, Register,
    ChangePasswordPath, ChangePasswordName, ChangePassword,
    ToolbarUser, ToolbarSubscribeApps,ToolbarFeedback
}


