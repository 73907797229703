<template>
    <el-form class="password-form" ref="FormRef" :model="formData" :rules="rules" label-position="top">
        <el-form-item v-if="isSetRegisterPassword" label="请输入邀请码" class="is-required" prop="invitationCode" :error="invitationCodeError">
            <InvitationCode v-model="formData.invitationCode" @focus="handleInvitationCodeFocus" />
        </el-form-item>
        <el-form-item v-if="showPhoneCodesFormItem" label="账号" prop="phone">
            <el-input v-model="formData.phone" placeholder="请输入账号" :disabled="isInitPassword || isChangePassword"></el-input>
        </el-form-item>
        <el-form-item v-if="showPhoneCodesFormItem" label="验证码" class="is-required" prop="verificationCodes" :error="enterVerificationCodeError">
            <EnterVerificationCode ref="EnterVerificationCodeRef" v-bind="getProps" v-model="formData.verificationCodes" @send="handleSend" @focus="handleEnterVerificationCodeFocus" />
        </el-form-item>

        <el-form-item label="密码" prop="password">
            <div style="font-size: 18px; color: #000; font-weight: normal">设置密码</div>
            <div>8-16个字符，前后不能有空格，区分大小写； 需包含字母、数字及特殊字符两种及以上组合。</div>
            <el-input v-model="formData.password" type="password" autocomplete="on" :disabled="disabledPassword" show-password></el-input>
            <div class="password-strength">
                <div style="margin-right: 10px">密码强度</div>
                <div style="margin-right: 10px">
                    <span>{{ passwordLevelLabel }}</span>
                </div>
                <template v-for="(item, index) in passwordLevels">
                    <div :key="index + '-bar'" :class="['strength-bar', !(index + 1 > passwordLevel) ? 'active' : '']"></div>
                </template>
            </div>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword">
            <el-input v-model="formData.confirmPassword" type="password" autocomplete="off" :disabled="disabledPassword" show-password></el-input>
        </el-form-item>
    </el-form>
</template>

<script>
import EnterVerificationCode from "./EnterVerificationCode.vue"
import InvitationCode from "./InvitationCode.vue"
export default {
    provide() {
        return {
            getFormData: () => this.formData,
        }
    },
    props: {
        type: {
            type: String,
            default: "register",
        },
        initPasswordFormData: {
            type: Object,
            default: () => {
                return {
                    memberId: "",
                    mobileNumber: "",
                }
            },
        },
        changePasswordFormData: {
            type: Object,
            default: () => {
                return {
                    memberId: "",
                    mobileNumber: "",
                }
            },
        },
    },
    components: {
        EnterVerificationCode,
        InvitationCode,
    },
    data() {
        return {
            types: ["register", "initPassword", "forgetPassword", "changePassword"],
            formData: {
                phone: "",
                password: "",
                confirmPassword: "",
                /** 验证码 通过v-model绑定EnterVerificationCode组件 */
                verificationCodes: "",
                /** 邀请码 */
                invitationCode: "",
            },
            /** 验证码 error信息 */
            enterVerificationCodeError: "",
            /** 邀请码 error信息 */
            invitationCodeError: "",
            /** 手机号 状态字典 */
            phoneStatus: [
                { value: "01", label: "正常" },
                { value: "02", label: "账号未注册或未分配" },
                { value: "03", label: "账号未授权，请先登录授权" },
            ],
            passwordLevels: [
                { value: 1, label: "弱", regExg: /^[a-z]{4,6}$|^[0-9]{4,6}$/ },
                { value: 2, label: "中", regExg: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,12}$|^(?=.*[a-z])(?=.*[\d])(?=.*[!@?#$%^&*])[A-Za-z\d!@?#$%^&*]{8,12}$/ },
                { value: 3, label: "强", regExg: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@?#$%^&*])[A-Za-z\d!@?#$%^&*]{12,}$/ },
            ],
            sendCodeTimeStamp: 0,
            loading: false,
            loadingInstance: null,
        }
    },
    computed: {
        /**
         * @returns {'register'|'initPassword'|'forgetPassword'|'changePassword'}
         */
        innerType() {
            let type = this.types[0]
            if (this.types.includes(this.type)) {
                type = this.type
            }
            return type
        },
        /** 当前为 设置注册密码 */
        isSetRegisterPassword() {
            return this.innerType === "register"
        },
        /** 当前为 初始化登录密码 */
        isInitPassword() {
            return this.innerType === "initPassword"
        },
        /** 当前为 忘记密码 */
        isForgetPassword() {
            return this.innerType === "forgetPassword"
        },
        /** 当前为 修改密码 */
        isChangePassword() {
            return this.innerType === "changePassword"
        },
        /** 是否展示-手机号、验证码-表单项  当前为-初始化登录密码、忘记密码、修改密码-展示 */
        showPhoneCodesFormItem() {
            return this.isInitPassword || this.isForgetPassword || this.isChangePassword
        },
        /** 禁用密码确定密码 当前为-初始化登录密码、修改密码(展示-手机号、验证码-表单项)，验证码未填完整 时，禁用 */
        disabledPassword() {
            return this.showPhoneCodesFormItem && !this.formData.verificationCodes
        },
        rules() {
            return {
                phone: [
                    {
                        required: true,
                        message: "请输入手机号码",
                        trigger: "blur",
                    },
                    {
                        pattern: /^1[3-9]\d{9}$/,
                        message: "手机号格式错误",
                        trigger: "blur",
                    },
                ],
                password: [
                    { required: true, message: "密码不能为空", trigger: "blur" },
                    { validator: this.validatePassword, trigger: "blur" },
                ],
                confirmPassword: [
                    { required: true, message: "请输入确认密码", trigger: "blur" },
                    { validator: this.validateConfirmPassword, trigger: "blur" },
                ],
                // invitationCodeProp: [
                //     { required: false, message: '请输入6位邀请码', trigger: 'blur' },
                // ]
            }
        },
        passwordLevel() {
            let find = this.passwordLevels.find(item => item.regExg.test(this.formData.password))
            if (find) {
                return find.value
            } else {
                return 1
            }
        },
        passwordLevelLabel() {
            let find = this.passwordLevels.find(item => item.value === this.passwordLevel)
            return find ? find.label : ""
        },
        getProps() {
            return {
                sendCodeTimeStamp: this.sendCodeTimeStamp,
                ...this.$attrs,
            }
        },
    },
    watch: {
        isInitPassword: {
            handler(val) {
                if (val) {
                    this.formData.phone = this.initPasswordFormData.mobileNumber
                }
            },
            immediate: true,
        },
        isChangePassword: {
            handler(val) {
                if (val) {
                    this.formData.phone = this.changePasswordFormData.mobileNumber
                }
            },
            immediate: true,
        },
    },
    methods: {
        validatePassword(rule, value, callback) {
            let regex = /^(?![\d]+$)(?![a-z]+$)(?![A-Z]+$)(?![!@?#$%^&*]+$)[\da-zA-z!@?#$%^&*.]{8,}$/
            if (!regex.test(value)) {
                return callback(new Error("密码需由数字、大小写英文字母或特殊符号组成，且需包含至少两种类型，长度不少于8个字符。"))
            }
            callback()
        },
        validateConfirmPassword(rule, value, callback) {
            // 检查确认密码是否与输入密码匹配
            if (this.formData.password && value !== this.formData.password) {
                callback(new Error("两次输入的密码不一致"))
            } else {
                callback()
            }
        },
        /** 验证码focus */
        handleEnterVerificationCodeFocus() {
            // console.log('handleEnterVerificationCodeFocus');
            this.enterVerificationCodeError = ""
        },
        validate() {
            return new Promise(async resolve => {
                // console.log('PasswordForm.valid', this.$refs.EnterVerificationCodeRef);
                let results = await Promise.all([
                    new Promise(resolve => {
                        this.$refs.FormRef.validate(valid => {
                            resolve(valid)
                        })
                    }),
                    new Promise(async resolve => {
                        if (this.showPhoneCodesFormItem) {
                            let enterVerificationCode = this.formData.verificationCodes
                            if (!/^.{6}$/.test(enterVerificationCode)) {
                                this.enterVerificationCodeError = "请输入6位短信验证码"
                                resolve(false)
                            } else {
                                resolve(true)
                            }
                        } else {
                            resolve(true)
                        }
                    }),
                    new Promise(async resolve => {
                        if (this.isSetRegisterPassword) {
                            let invitationCode = this.formData.invitationCode
                            if (!/^.{6}$/.test(invitationCode)) {
                                this.invitationCodeError = "请输入6位邀请码"
                                resolve(false)
                            } else {
                                resolve(true)
                            }
                        } else {
                            resolve(true)
                        }
                    }),
                ])
                if (results.some(result => !result)) {
                    resolve(false)
                } else {
                    if (this.isSetRegisterPassword) {
                        let invitationCodeValid = await this.checkInvitationCode()
                        console.log(invitationCodeValid, "invitationCodeValid")

                        if (!invitationCodeValid) return
                    }

                    // console.log('PasswordForm.valid else', this.formData);
                    resolve({
                        ...this.formData,
                        // ...this.showPhoneCodesFormItem ? { verificationCodes: results[1] } : {}
                    })
                }
            })
        },
        /** 邀请码focus */
        handleInvitationCodeFocus() {
            // console.log('handleInvitationCodeFocus');
            this.invitationCodeError = ""
        },
        /**
         * @description 校验邀请码
         */
        checkInvitationCode() {
            return new Promise((resolve, reject) => {
                this.setLoading()
                this.api
                    .getListAPI(
                        {
                            invitationCode: this.formData.invitationCode,
                        },
                        "/unifyauth/tenant/checkInvitationCode",
                        {
                            errorMessage: "无效邀请码",
                        }
                    )
                    .then(() => {
                        this.closeLoading()
                        resolve(true)
                    })
                    .catch(error => {
                        this.closeLoading()
                        resolve(false)
                    })
            })
        },
        setLoading() {
            this.loading = true
            this.loadingInstance = this.$loading({
                background: "transparent",
                spinner: "",
            })
        },
        closeLoading() {
            this.loading = false
            this.loadingInstance && this.loadingInstance.close()
        },
        /** 发送手机验证码 */
        async handleSend() {
            console.log(
                "PasswordForm, handleSend",
                `isInitPassword:${this.isInitPassword}, 
            isForgetPassword:${this.isForgetPassword}, isChangePassword:${this.isChangePassword}`
            )

            // if (!(this.isForgetPassword || this.isChangePassword)){
            //     this.$emit('send');
            //     return;
            // }
            let phoneValid = await new Promise(resolve => {
                this.$refs.FormRef.validateField("phone", errorMessage => {
                    console.log("validateField.phone", errorMessage)
                    resolve(!!errorMessage)
                })
            })
            if (phoneValid) return
            this.setLoading()
            // 忘记密码 发送验证码
            try {
                if (this.isForgetPassword) {
                    let message = await this.checkForgotPasswordPhone(this.formData.phone)
                    if (typeof message === "string" && message) {
                        this.$message({
                            type: "error",
                            message,
                        })
                    }
                }

                await this.sendVerificationCode(this.formData.phone)
                this.sendCodeTimeStamp = Date.now()
            } catch (error) {}
            this.closeLoading()
        },
        /**
         * @param {*} mobileNumber 手机号
         * @description 检测忘记密码的手机号是否已存在系统中
         */
        checkForgotPasswordPhone(mobileNumber) {
            return new Promise((resolve, reject) => {
                this.api
                    .getListAPI(
                        {
                            mobileNumber,
                        },
                        // unifyauth/tenant/checkForgotPassword?mobileNumber=15516366569
                        "/unifyauth/tenant/checkForgotPassword"
                    )
                    .then(res => {
                        // console.log('checkForgotPassword', res.data);
                        let data = res.data.data
                        let find = this.phoneStatus.find(item => typeof data === "string" && item.value === data)
                        if (!find) {
                            return reject("服务异常")
                        }
                        if (find.value === "01") {
                            return resolve(true)
                        } else {
                            resolve(find.label)
                        }
                    })
                    .catch(error => {
                        console.log("error", error)
                        reject("服务异常")
                    })
            })
        },
        /**
         * @param {*} mobileNumber 手机号
         * @description 给忘记密码的手机号发送验证码
         */
        async sendVerificationCode(phone) {
            return new Promise((resolve, reject) => {
                this.api
                    .getListAPI(
                        {
                            phone,
                        },
                        "/unifyauth/sms/sendToChangPassword",
                        {
                            showSuccessMessage: true,
                            successMessage: "发送成功",
                        }
                    )
                    .then(() => {
                        resolve(true)
                    })
                    .catch(error => {
                        reject(error)
                        console.log("sendToChangPassword.error", error)
                    })
            })
        },
    },
}
</script>

<style lang="less">
.password-form {
    & .el-form-item__label {
        padding: 0 !important;
    }
    & .password-strength {
        display: flex;
        align-items: center;
        height: 20px;
        margin-top: 10px;
        & > .strength-bar {
            width: 30px;
            height: 5px;
            background-color: #e0e0e0;
            margin-left: 5px;
            transition: background-color 0.3s;
            &.active {
                background-color: #3370ff;
            }
        }
        &,
        & * {
            line-height: normal;
        }
    }
}
</style>
