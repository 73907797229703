<template>
    <div id="choiceProduct">
        <el-dialog v-dialogDrag class="choiceProductDialog" :title="title" :visible.sync="visible" :modal-append-to-body="false" @close="handleClose" :show-close="false">
            <div class="left-wrapper">
				<div class="title">
					<div>已选择 {{ currentNodeName }}</div>
				</div>
				<div class="left-body wrapper-body">
					<el-input v-model="productName" size="small" placeholder="请输入应用名称"></el-input>
					<el-tree
                        :data="Treedata"
                        ref="tree_ref"
                        :props="defaultProps"
                        :expand-on-click-node="false"
                        :current-node-key="currentNodeId"
                        node-key="id"
                        highlight-current
                        accordion
                        @node-click="handleNodeClick"
                        :default-expand-all="true"
                    >
                        <div :class="['my-custom-label', currentNodeId === data.id ? 'current' : '']" slot-scope="{ node, data }">
							<span class="name" v-html="handleSetFilterHighlight(data.productName)"></span>
                            <span class="description" v-html="handleSetFilterHighlight(data.productDescription)"></span>
							<span class="version" v-html="handleSetFilterHighlight(data.versionNumber)"></span>
                        </div>
                    </el-tree>
                    <!-- :filter-node-method="filterNode" -->
				</div>
			</div>
			<div class="right-wrapper">
				<div class="title">
					<div>
						已选择
						<span :style="{ color: selectionIds.length ? 'var(--main-bg-color)' : 'red' }">
                            {{ selectionIds.length }}
                        </span>
						项
					</div>
                    <div class="check-strictly">
                        <span>开启关联</span>
                        <el-switch v-model="checkStrictly"></el-switch>
                    </div>
				</div>
				<div class="right-body wrapper-body">
					<el-input v-model="moduleApiName" size="small" placeholder="请输入模块/接口名称" @change="handleModuleApiInputChange"></el-input>
					
                    
                    <el-tree
                        ref="treeModuleApiRef"
                        v-bind="moduleApiAttrs"
                        @check="handleCheck"
                    >
                    </el-tree>
				</div>
			</div>
			<div class="action">
                <el-button type="primary" size="small" @click="handleSubmit">确 定</el-button>
                <el-button size="small" @click="handleClose">取 消</el-button>
            </div>
            
        </el-dialog>
    </div>
</template>
<script>
import { Button, Tree, Dialog } from "element-ui"
import { MessageBox, Message } from "element-ui"
import api from '@/api/api';
export default {
    name: "choiceProduct",
    components: {
        // "el-button": Button,
        // "el-tree": Tree,
        // "el-dialog": Dialog,
    },
    data() {
        return {
            title: "权限配置",
            visible: false,
            multiple: false, //多选
            required: true, //是否必须至少选择一项
            originTreeData: [],
            Treedata: [],
            productName: "",
			moduleApiName: '',
            filterTimeout: null,
            currentNodeId: "",
            currentNodeName: "",
            orgTimeout: null,
            selection: [], //回显数组 通过id,id没有就用realName
			selectionIds: [],
            // currentUserId: '',
            // currentUserRealname: '',
            // currentUsername: '',
            checkStrictly: false,

            filterUser: "",
            loading: false,
			roleId: '',
            pagination: {
                pageNum: 1,
                pageSize: 100,
                total: 0,
            },
            tableData: [],
            userTimeout: null,
            successCallback: null,
            defaultDisabledIds: [], //禁用选择id或realName 数组
        }
    },
    mounted() {
    },
    computed: {
        moduleApiAttrs(){
            return {
                data: this.tableData,
                props: {
                    children: 'children',
                    label: 'moduleName',
                },
                expandOnClickNode: true,
                nodeKey: "id",
                showCheckbox: true,
                highlightCurrent: true,
                defaultExpandAll: true,
                checkStrictly: !this.checkStrictly,
                filterNodeMethod: (value, data) => {
                    if (!value) return true;
                    // console.log('filterNode', data.moduleName, value);
                    
                    return data.moduleName.indexOf(value) !== -1;
                }
            };
        },
        defaultProps() {
            return {
                children: "children",
                label: "productName",
            }
        },
    },
    watch: {
        visible(val) {
            // console.log("choiceProduct, watch, visible", val, this.defaultProps, this.title)
            if (val) {
                this.getTreeList()
            } else {
                this.productName = "";
                this.filterUser = "";
                this.currentNodeId = "";
				this.roleId = '';
                this.checkStrictly = false;
                this.currentNodeName = "";
                this.selectionIds = [];
                this.Treedata = [];
                this.tableData = [];
                this.defaultDisabledIds = [];
                this.required = true;
                this.multiple = false
            }
        },
        productName(val) {
            if (!this.visible) return
            this.filterTimeout && window.clearTimeout(this.filterTimeout)
            this.filterTimeout = setTimeout(() => {
                // this.handleFilter()
				this.getTreeList();
            }, 500)
        },
        currentNodeId(val) {
            if (!val) return
            this.orgTimeout && window.clearTimeout(this.orgTimeout)
            this.orgTimeout = setTimeout(() => {
                this.pagination = {
                    ...this.$options.data().pagination,
                }
                this.handleSearch()
            }, 500)
        },
        filterUser(val) {
            // if (!val) return
            this.userTimeout && window.clearTimeout(this.userTimeout)
            this.userTimeout = setTimeout(() => {
                this.pagination = {
                    ...this.$options.data().pagination,
                }
				this.loading = true;
				this.getTableData().then(() => {
					this.handleSetCheckedKeys();
				}).catch(error => {
					
				}).finally(() => {
					this.loading = false;
				})
            }, 500)
        },
    },
    methods: {
        getTreeList() {
            // console.log("getTreeList", this.originTreeData, this.$store);
            api.postFormAPIProduct(
				{
					productName: this.productName,
				},
				'queryTenantProduct',
            ).then(res => {
                this.originTreeData = res.data.data || [];
                this.Treedata = res.data.data || [];
				if (this.Treedata[0]){
					this.handleNodeClick(res.data.data[0]);
				} else {
					this.selectionIds = [];
					this.currentNodeId = '';
					this.currentNodeName = '';
					this.tableData = [];
				}
                
            })
            .catch(err => {
                console.log(err)
            })
        },
        handleFilter() {
            console.log("handleFilter", this.originTreeData)
            let target = []
            let getChildren = (children, value) => {
                let temp = []
                if (!(children instanceof Array) || !children.length) return []
                for (let i = 0; i < children.length; i++) {
                    // 当前项是否匹配value   value为空''  也匹配
                    let include = children[i]['productName'].includes(value) || children[i]['productDescription'].includes(value) || children[i]['versionNumber'].includes(value)
                    // 当前项子集
                    let tempChildren = children[i][this.defaultProps.children]
                    // 若不匹配  且当前项子集children不存在 ，为叶子节点  直接continue
                    if (!include && (!(tempChildren instanceof Array) || !tempChildren.length)) continue
                    // 子集递归筛选   当前项  若匹配 则筛选字段为空  若不匹配 筛选为value
                    let subChildren = getChildren(tempChildren, include ? "" : value)

                    // 若当前项不匹配  且当前项子集children不存在  直接continue；
                    if (!include && !subChildren.length) continue
                    let suffix = {}
                    suffix[this.defaultProps.children] = subChildren
                    temp.push({
                        ...children[i],
                        ...suffix,
                    })
                }
                // console.log('temp', temp);
                return temp
            }
            target = getChildren(this.originTreeData, this.productName)
            // console.log('handleFilter', target);
            this.Treedata = target
        },
        handleSetFilterHighlight(label) {
            if (!this.productName) return label
            return label.replace(new RegExp(`${this.productName}`, "g"), `<span class="highlight">${this.productName}</span>`)
        },
        handleNodeClick(data) {
			this.selectionIds = [];
            this.currentNodeId = data.id;
            this.currentNodeName = data[this.defaultProps.label];
            // this.$refs.tree_ref.setCurrentKey(this.currentNodeId);
            this.$nextTick(() => {
                this.$refs.tree_ref.setCurrentKey(this.currentNodeId);
            });
        },
		/**
         * @description 查询租户角色下关联的应用模块信息ID
        */
        queryTenantRoleModuleId(){
            return new Promise((resolve, reject) => {
                if (!this.currentNodeId || !this.roleId){
                    return reject(false);
                }
                this.api.postFormAPIProduct(
                    {
                        productId: this.currentNodeId,
						roleId: this.roleId,
                    },
                    'queryTenantRoleModuleId',
                ).then(res => {
					this.selectionIds = res.data.data || [];
					resolve(res.data.data || []);
				}).catch(error => {
					reject(error);
				})
            })
        },
		handleSearch(){
			this.loading = true;
			Promise.all([
				this.queryTenantRoleModuleId(),
				this.getTableData(),
			]).then(() => {
				this.handleSetCheckedKeys();
			}).catch(error => {
				console.log('error', error);
				
			}).finally(() => {
				this.loading = false;
			})
		},
		handleSetCheckedKeys(){
            this.$nextTick(() => {
                this.$refs.treeModuleApiRef.setCheckedKeys(this.selectionIds);
            })
            return;
            
			for (let i = 0; i < this.selectionIds.length; i++) {
				let find = this.findTableDataRow(this.selectionIds[i]);
				this.$nextTick(() => {
					console.log('handleSetCheckedKeys-i', find);
					
					find && this.$refs.table_ref.toggleRowSelection(find, true)
				})
				// let findIndex = this.tableData.findIndex(item => item.id === this.selectionIds[i].id || item.realName === this.selectionIds[i].realName)
				// if (findIndex > -1) {
				// 	console.log("findIndex", findIndex)
				// 	this.$nextTick(() => {
				// 		this.$refs.table_ref.toggleRowSelection(this.tableData[findIndex], true)
				// 	})
				// }
			}
			console.log('handleSetCheckedKeys', this.selectionIds);
			
		},
        /**
         * @description 模块/接口 输入框失焦/enter
        */
        handleModuleApiInputChange(value){
            console.log('handleModuleApiInputChange', value, this.moduleApiName);
            this.$refs.treeModuleApiRef.filter(value);
        },
        /**
         * @description 模块/接口 节点勾选变更回调
        */
        handleCheck(){
            this.selectionIds = this.$refs.treeModuleApiRef.getCheckedKeys();
        },
		/** @param {string} id  */
		findTableDataRow(id){
			/**
			 * @param {{id: string}[]} data
			*/
			let findRow = (data) => {
				let find;
				for (let i in data){
					if (data[i].id === id){
						find = data[i];
					}
					else if (Array.isArray(data[i].children)){
						find = findRow(data[i].children);
					}
					if (find){
						break;
					}
				}
				return find
			};
			return findRow(this.tableData);
		},

        getTableData() {
			return new Promise((resolve, reject) => {
                if (!this.currentNodeId){
                    return reject(false);
                }
				let getTreeChildrenIds = (children, include) => {
					// children递归的子集数组  include 对子集是否全部获取orgId
					let childrenIds = []
					for (let i = 0; i < children.length; i++) {
						let subChildren = children[i][this.defaultProps.children]
						if (!(subChildren instanceof Array) || !subChildren.length) {
							subChildren = []
						}
						if (include || children[i].id === this.currentNodeId) {
							// console.log('include, children[i].id === this.currentNodeId', include, children[i].id, children[i][this.defaultProps.label]);
							childrenIds = [...childrenIds, children[i].id, ...getTreeChildrenIds(subChildren, true)]
							if (!include) break
						} else {
							if (!subChildren.length) continue
							childrenIds = getTreeChildrenIds(subChildren, false)
							if (childrenIds.length) break
						}
					}
					return childrenIds
				}
				let childrenIds = getTreeChildrenIds(this.originTreeData, false)
				api.postFormAPIProduct(
					{
						productId: this.currentNodeId
					},
					'queryTenantProductModuleTree'
				).then(res => {
					// console.log(res)
					this.tableData = res.data.data || [];
					// this.pagination.total = res.data.total;
					resolve();
				}).catch(error => {
					console.log('error', error);
					reject(error);
				})
			});
        },
        handleSizeChange(pageSize) {
            this.pagination.pageSize = pageSize
            this.pagination.pageNum = 1
            this.getTableData()
        },
        handleCurrentChange(pageNum) {
            this.pagination.pageNum = pageNum
            this.getTableData()
        },
        selectable(row) {
            return !this.defaultDisabledIds.includes(row.id) && !this.defaultDisabledIds.includes(row.realName)
        },
        handleRowClick(row) {
            console.log("handleRowClick", this.defaultDisabledIds, this.defaultDisabledIds.includes(row.id))
            if (!this.selectable(row)) {
                // this.$message({
                //     type: 'warning',
                //     message: `${ row.realName }不能同时是检测员和校核员`
                // });
                return
            }
            // if(!this.defaultDisabledIds.includes(row.id)){
            //     this.handleSelect("", row)
            // }
            this.handleSelect("", row)
        },
        handleSelect(selection, row) {
            // 查询点击行 是否在selection 中
            let findIndex = this.selectionIds.findIndex(id => id === row.id);
            console.log("handleSelect", row.id, findIndex)
            if (findIndex > -1) {
                // 点击或勾选的是 已存在 的行，则要取消
                if (!this.multiple) {
                    //单选 要先清除其他勾选
                    this.$refs.table_ref.clearSelection()
                }
                this.$refs.table_ref.toggleRowSelection(row, false)
                this.selectionIds.splice(findIndex, 1)
            } else {
                // 点击或勾选的是 不存在 的行，则要勾选
                if (!this.multiple) {
                    //单选 要先清除其他勾选
                    this.$refs.table_ref.clearSelection()
                    this.selectionIds = []
                }
                this.$refs.table_ref.toggleRowSelection(row, true)
                this.selectionIds.push(row.id)
            }
        },
        handleSelectAll(selection) {
            if (!this.multiple) return
            let temp = this.selectionIds.map(item => {
                return item
            })
            if (selection.length) {
                temp = [
                    ...temp,
                    ...selection.map(item => {
                        return item;
                    })
                ]
                console.log("handleSelectAll", selection, temp)
                this.selectionIds = temp.filter((item, index) => index === temp.findIndex(Item => (item && Item === item)))
                console.log("handleSelectAll.selection", this.selectionIds)
            } else {
                this.selectionIds = temp.filter((item, index) => index !== this.tableData.findIndex(Item => (item && item=== Item)))
            }
        },
        //表格选中背景色
        rowClass({ row }) {
            if (this.selectionIds.findIndex(id => id === row.id) > -1) {
                return { "background-color": "#ECF5FF" }
            }
        },
        handleClose() {
            this.tableData = []
            this.visible = false
        },
        handleSubmit() {
            console.log("handleSubmit", this.required, this.selectionIds);
            if (this.required && !this.selectionIds.length) {
                return MessageBox.alert("至少选择一项！", "提示", {
                    confirmButtonText: "确定",
                    type: "warning",
                    showClose: true,
                    customClass: "labMessageClass",
                })
                    .then(() => {})
                    .catch(() => {})
            }

            let data = this.selectionIds.map(item => {
                return item;
            })
            if (typeof this.successCallback === "function") {
                this.successCallback(data, this.currentNodeId)
            }
            this.visible = false
            console.log("handleSubmit", data)
        },
    },
}
</script>
<style lang="less">
#choiceProduct {
    & > .choiceProductDialog {
        & > .el-dialog {
			min-width: 1200px;
			height: 75vh;
            min-height: 600px;
            // height: 400px;

            & > .el-dialog__header {
                // cursor: move;
                // -webkit-app-region: drag;
                padding: 12px 10px !important;
                // background-image: linear-gradient(rgb(6, 194, 122), rgb(1, 133, 89));
                background-image: linear-gradient(538deg, var(--main-bg-color), var(--main-bg-color)) !important;

                & > .el-dialog__title {
                    font-size: 16px !important;
                    color: white !important;
                    font-weight: bold !important;
                }

                & > .el-dialog__headerbtn {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    padding: 0;
                    background: 0 0;
                    border: none;
                    outline: 0;
                    cursor: pointer;
                    font-size: 16px;

                    & > .el-dialog__close {
                        color: white !important;
                        font-size: 21px !important;
                        position: relative !important;
                        top: -6px !important;
                    }
                }
            }

            & > .el-dialog__body {
                height: calc(100% - 48px);
				padding: 0 20px 20px;
                display: grid;
				grid-template-rows: 1fr auto;
				grid-template-columns: 1fr 1fr;
				grid-template-areas: 'left-wrapper right-wrapper' 'action action';
				row-gap: 20px;
				grid-row-gap: 20px;
				column-gap: 20px;
				grid-column-gap: 20px;
				&>.left-wrapper{
					grid-area: 'left-wrapper';
				}
				&>.right-wrapper{
					grid-area: 'right-wrapper';
				}
				&>.left-wrapper,
				&>.right-wrapper{
					overflow: hidden;
					display: flex;
					flex-flow: column nowrap;
					&>.title{
						flex-shrink: 0;
						height: 24px;
						margin: 12px 0 10px 0;
						font-weight: 600;
						color: #000;
						display: flex;
						align-items: center;
                        &>.check-strictly{
                            flex-grow: 1;
                            display: flex;
                            align-self: stretch;
                            justify-content: flex-end;
                            align-items: center;
                            &>span{
                                margin: 0 10px 0 0;
                            }
                        }
					}
					&>.wrapper-body{
						flex-grow: 1;
						overflow: hidden;
						border: 1px solid #ccc;
						padding: 14px;
						display: flex;
						flex-flow: column nowrap;
						&>div{
							flex-shrink: 0;
						}
						&>.el-table{
							margin: 14px 0 0;
							&>.el-table__body-wrapper{
								height: calc( 100% - 42px );
								overflow-y: auto;
							}
						}
						&>.el-tree{
                            flex-shrink: 1;
							flex-grow: 1;
							margin: 14px 0 0;
                            overflow: auto;
							& .my-custom-label{
								padding: 0 14px 0 0;
								overflow: hidden;
								display: flex;
								flex-flow: row nowrap;
								align-items: center;
								&>span{
									flex-shrink: 0;
									&.description{
										flex-grow: 1;
										flex-shrink: 1;
										white-space: nowrap;
										text-overflow: ellipsis;
										overflow: hidden;
									}
									& + span{
										margin: 0 0 0 20px;
									}
								}
							}
						}
					}
				}
				&>.action{
					grid-area: action;
					display: flex;
					justify-content: flex-end;
				}

                & > .el-button {
                    margin-right: 1%;
                    align-self: flex-end;
                }
            }
        }
    }
}
</style>
