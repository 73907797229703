<template>
    <div class="invitation-code">
        <div class="invitation-code__wrapper">
            <el-input v-for="(code, index) in codes" :key="index" v-model="codes[index]"
                type="text" maxlength="1" @input="handleInput(index)" @focus="handleFocus"
                @keyup.native="onKeyUp(index, $event)" ref="inputs"></el-input>
        </div>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
    model: {
        prop: 'modelValue',
        event: 'input',
    },
    data() {
        return {
            /** 邀请码 */
            codes: ["", "", "", "", "", ""],
        }
    },
    watch: {
        codes: {
            handler() {
                this.$emit('input', this.codes.join(''));
            },
            deep: true,
            immediate: false,
        },
    },
    methods: {
        handleInput(index) {
            const value = this.codes[index]
            if (value !== "") {
                // 如果当前输入框的值不为空，则自动切换到下一个输入框
                if (index < this.codes.length - 1) {
                    this.$nextTick(() => {
                        this.$refs.inputs[index + 1].focus()
                    })
                }
            }
        },
        handleFocus() {
            this.$emit('focus');
        },
        onKeyUp(index, event) {
            if (event.key === "Backspace" && index > 0 && !this.codes[index]) {
                this.$nextTick(() => {
                    this.$refs.inputs[index - 1].focus()
                })
            }
        },

    }
}
</script>

<style lang="less">
.invitation-code {
    &>.invitation-code__wrapper {
        display: flex;
        flex-flow: row nowrap;
        column-gap: 5px;

        &>.el-input {
            width: 50px;
        }
    }

    &,
    & * {
        line-height: normal;
    }
}
</style>