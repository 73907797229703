
/** 门户主页/控制台 前台路由布局组件 */
const LayoutIndex = () => import("@/components/LayoutIndex/index");

/** 门户主页 */
const Index = () => import("@/views/Index");

/** 控制台前台路由组件 */
const Gateway = () => import("@/views/Gateway");

/** 控制台后台路由布局组件 */
const Layout = () => import("@/components/Layout");

export {
	LayoutIndex,
	Index,
	Gateway,
	Layout,
}

