import store from "@/store";
let electronVersion;
/** 当前运行环境是否为electron */
let isElectron = false;
try {
	electronVersion = process.versions.electron;
	isElectron = !!electronVersion;
} catch (error) {

}

/** 监听事件channel */
const CHANNELS = {
	/** 获取本地存储的账号的事件名称  默认 store.get-local-accounts */
	CHANNEL_GET_LOCAL_ACCOUNTS: 'store.get-local-accounts',
	/** 存储账号的事件名称  默认 store.set-local-accounts */
	CHANNEL_SET_LOCAL_ACCOUNTS: 'store.set-local-accounts',
	/** 删除指定账号的事件名称  默认 store.remove-local-accounts */
	CHANNEL_REMOVE_LOCAL_ACCOUNTS: 'store.remove-local-accounts',

	/** 获取本机字体列表  默认 get-font-list */
	CHANNEL_GET_FONT_LIST: 'get-font-list',
}


/**
 * @returns {import("electron").IpcRenderer}
*/
const getIpcRenderer = () => {
	let ipcRenderer;
	if (isElectron && window.ipcRenderer){
		ipcRenderer = window.ipcRenderer;

	} else {
		ipcRenderer = {
			send: (...args) => {
				console.log('当前运行环境为浏览器！', 'send', ...args);
			},
			on: (...args) => {
				// console.log('当前运行环境为浏览器！', 'on', ...args);
			},
			once: (...args) => {
				// console.log('当前运行环境为浏览器！', 'on', ...args);
			},
			removeAllListeners: (...args) => {
				// console.log('当前运行环境为浏览器！', 'removeAllListeners', ...args);
			},
		}
	}
	return ipcRenderer;
}

const ipcRenderer = getIpcRenderer();

// /** @description 监听主进程消息 */
const mountedEventListener = () => {
	ipcRenderer.on('maximize', () => {
		store.commit('setIsMaximized', true);
	});
	ipcRenderer.on('unmaximize', () => {
		store.commit('setIsMaximized', false);
	});
}
mountedEventListener();
if (isElectron){
	document.body.classList.add('is-electron');
};

/**
 * @param {{ account: String; password: String }} params 账号密码
 * @description electron环境下登录时 将勾选自动登录的账号密码传给主进程进行存储
*/
const setLocalAccount = (params) => {
	ipcRenderer.send('store.set-local-accounts', params);
}

/** 是否为开发环境 */
const isDev = process.env.NODE_ENV === 'development';

console.log('isElectron', electronVersion, isElectron);

// export default isElectron;

export {
	electronVersion,
	isElectron,
	ipcRenderer,
	CHANNELS,
	isDev,
	setLocalAccount,
}