import Vue from "vue"
import VueRouter from "vue-router"
Vue.use(VueRouter)
import packageJSON from "../../package.json"
const productName = packageJSON.productName

import { LayoutIndex, Index, Gateway, Layout } from "./constant"
import { LoginPath, LoginName, Login, RegisterPath, RegisterName, Register, ChangePasswordPath, ChangePasswordName, ChangePassword } from "@/core"
import configRoutes from "./config"

/** 后台首页路由地址 */
const HomePath = configRoutes[0].path

/** 初始路由地址 */
const IndexPath = "/"

/** 控制台/网关地址 */
const GatewayPath = "/Gateway"

var userAgent = navigator.userAgent || navigator.vendor || window.opera

// 使用正则表达式来检查userAgent字符串中是否包含某些关键字
// 这些关键字通常与移动设备相关
var mobile = /windows phone|iphone|ipad|ipod|android|blackberry|mini|windows ce|palm/i.test(userAgent.toLowerCase())


/** 移动端注册 */
import MRegister from "@/views/MRegister"
/** 移动端产品页面 */
import MIndex from "@/views/MIndex"
/** 移动端LayoutIndex */
import MLayoutIndex from "@/views/MLayoutIndex"
const routes = [
    // 门户主页/控制台前台
    {
        path: "/",
        component: mobile ? MLayoutIndex : LayoutIndex,
        children: [
            {
                path: "/",
                name: "Index",
                component: mobile ? MIndex : Index,
            },
            // 控制台/网关
            {
                path: GatewayPath,
                name: "Gateway",
                component: Gateway,
            },
        ],
        beforeEnter: (to, from, next) => {
            document.title = productName
            next()
        },
    },
    // 登录
    {
        path: LoginPath,
        name: LoginName,
        component: Login,
        beforeEnter: (to, from, next) => {
            document.title = productName
            next()
        },
    },
    // 注册
    {
        path: RegisterPath,
        name: RegisterName,
        component: mobile ? MRegister : Register,
        beforeEnter: (to, from, next) => {
            document.title = productName
            next()
        },
    },
    // 修改密码
    {
        path: ChangePasswordPath,
        name: ChangePasswordName,
        component: ChangePassword,
        beforeEnter: (to, from, next) => {
            document.title = productName
            next()
        },
    },
    // 控制台后台
    {
        path: "/Layout",
        name: "Layout",
        redirect: configRoutes[0].path,
        component: Layout,
        children: configRoutes,
        beforeEnter: (to, from, next) => {
            document.title = "管理后台"
            next()
        },
    },
    {
        path: "*",
        redirect: "/",
    },
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
})

// 路由守卫

export default router
// export const routerMenu = routes[0].children[0].children.filter(item => item.meta.showInSideMenu)
export { HomePath, IndexPath, configRoutes, LoginPath, RegisterPath, ChangePasswordPath, GatewayPath }
