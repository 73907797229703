1<template>
    <div id="MRegister">
        <div v-if="currentPage == '1'" class="register-mobile">
            <div class="el-page-header__left">
                <i class="el-icon-back" @click="goLogin"></i>
                <p @click="goLogin">返回</p>
            </div>
            <div class="register-mobile--title">完成企业注册，开启小乐云免费试用！</div>
            <el-form ref="FormRef" :model="registerMobileFormData" :rules="rules">
                <el-form-item prop="phone">
                    <el-input v-model="registerMobileFormData.phone" placeholder="请输入手机号">
                        <el-select slot="prepend" v-model="registerMobileFormData.regionType" style="width: 88px">
                            <el-option-group v-for="group in options" :key="group.label" :label="group.label">
                                <el-option v-for="item in group.options" :key="item.value" :label="item.label" :value="item.value">
                                    {{ item.optionLabel }}
                                </el-option>
                            </el-option-group>
                        </el-select>
                    </el-input>
                </el-form-item>
                <el-form-item label="" prop="enterVerificationCode" :error="enterVerificationCodeError">
                    <EnterVerificationCode
                        v-model="registerMobileFormData.enterVerificationCode"
                        :showTitle="false"
                        :sendCodeTimeStamp="sendCodeTimeStamp"
                        @send="handleSend"
                        @focus="handleEnterVerificationCodeFocus"
                    />
                </el-form-item>
            </el-form>
            <div class="register-mobile-foot">
                <servicePrivacy v-model="checked" />
                <el-button type="primary" @click="sure">确定</el-button>
            </div>
        </div>
        <div v-if="currentPage == '2'" class="set-register-password">
            <div class="el-page-header__left">
                <i class="el-icon-back" @click="goLogin"></i>
                <p @click="goLogin">返回</p>
            </div>
            <PasswordForm type="register" ref="PasswordFormRef" class="PasswordForm" />
            <div class="register-mobile-foot">
                <el-button type="primary" @click="sure">确定</el-button>
            </div>
        </div>
        <div v-if="currentPage == '3'" class="fill-in-information">
            <div class="el-page-header__left">
                <i class="el-icon-back" @click="goLogin"></i>
                <p @click="goLogin">返回</p>
            </div>
            <div class="fill-in-information--title">请完善您和您企业的信息。</div>
            <el-form ref="fillInInformationFormRef" :model="fillInInformationFormData" :rules="fillInInformationRules">
                <el-form-item label="您的姓名" prop="memberName">
                    <el-input size="small" v-model="fillInInformationFormData.memberName" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="企业或组织名称" prop="companyName">
                    <el-input size="small" v-model="fillInInformationFormData.companyName" placeholder="请输入" @input="handleCompanyNameInput"></el-input>
                    <div style="line-height: normal; color: orange; font-size: 12px">{{ companyNameValidateMessage }}</div>
                </el-form-item>
                <el-form-item label="企业或组织简称" prop="companyAlias">
                    <el-input size="small" v-model="fillInInformationFormData.companyAlias" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="企业或组织规模" prop="companySize">
                    <el-select style="width: 100%" size="small" v-model="fillInInformationFormData.companySize" placeholder="请选择">
                        <el-option v-for="item in organizationalScale" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="您的职位" prop="position">
                    <el-input size="small" v-model="fillInInformationFormData.position" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="所在地区" prop="companyLocation">
                    <el-cascader size="small" style="width: 100%" :options="cityData" v-model="fillInInformationFormData.companyLocation" clearable filterable placeholder="请选择省份/市" />
                </el-form-item>
            </el-form>
            <div class="register-mobile-foot">
                <el-button type="primary" @click="sure">确定</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { pcTextArr as cityData } from "element-china-area-data"
import PasswordForm from "./PasswordForm.vue"
import servicePrivacy from "./service-privacy.vue"
import EnterVerificationCode from "./EnterVerificationCode.vue"
export default {
    name: "MRegister",

    data() {
        return {
            organizationalScale: [
                {
                    value: "01",
                    label: "20人以下",
                },
                {
                    value: "02",
                    label: "20-99",
                },
                {
                    value: "03",
                    label: "100-199",
                },

                {
                    value: "04",
                    label: "200-499",
                },
                {
                    value: "05",
                    label: "500-999",
                },
                {
                    value: "06",
                    label: "1000-9999",
                },
                {
                    value: "07",
                    label: "10000人以上",
                },
            ],
            cityData,
            companyNameValidateMessage: "",
            fillInInformationRules: {
                memberName: [{ required: true, message: "请输入您的姓名", trigger: "blur" }],
                companyName: [
                    { required: true, message: "请输入企业或组织名称", trigger: "blur" },
                    { validator: this.validateUsername, trigger: "blur" },
                ],
                companyAlias: [{ required: true, message: "请输入企业或组织简称", trigger: "blur" }],
                companySize: [
                    {
                        required: true,
                        message: "请选择企业或组织规模",
                        trigger: "change",
                    },
                ],
                position: [{ required: true, message: "请输入您的职位", trigger: "blur" }],
                companyLocation: [{ required: true, message: "请选择所在地区", trigger: "change" }],
            },
            fillInInformationFormData: {
                memberName: "",
                companyName: "",
                companyAlias: "",
                companySize: "",
                position: "",
                companyLocation: [],
            },
            checked: false,
            currentPage: "1",
            rules: {
                phone: [
                    {
                        required: true,
                        message: "请输入手机号码",
                        trigger: "blur",
                    },
                    {
                        pattern: /^1[3-9]\d{9}$/,
                        message: "手机号格式错误",
                        trigger: "blur",
                    },
                ],
            },
            registerMobileFormData: {
                phone: "",
                regionType: "01",
                /** 短信验证码 */
                enterVerificationCode: "",
            },
            options: [
                {
                    label: "请选择国家或地区",
                    options: [
                        {
                            value: '01',
                            label: '+86',
                            optionLabel: '+86 中国大陆',
                        },
                        {
                            value: '02',
                            label: '+852',
                            optionLabel: '+852 中国香港',
                        },
                        {
                            value: '03',
                            label: '+853',
                            optionLabel: '+853 中国澳门',
                        },
                        {
                            value: '04',
                            label: '+886',
                            optionLabel: '+886 中国台湾',
                        },
                    ],
                },
            ],
            enterVerificationCodeError: "",
            sendCodeTimeStamp: 0,
            loading: false,
        }
    },
    components: {
        servicePrivacy,
        EnterVerificationCode,
        PasswordForm,
    },
    methods: {
        handleCompanyNameInput() {
            this.companyNameValidateMessage = ""
        },
        async validateUsername(rule, value, callback) {
            await this.api
                .getListAPI(
                    {
                        companyName: value,
                    },
                    "/unifyauth/tenant/verify"
                )
                .then(res => {
                    let code = res.data.data
                    let codes = [
                        { label: "", value: "01" },
                        { label: "企业名称已存在", value: "02" },
                        { label: "存在相似企业名称", value: "03" },
                    ]
                    let findCode = codes.find(item => item.value === code)
                    if (findCode) {
                        this.companyNameValidateMessage = findCode.label
                    }
                    callback()
                })
                .catch(error => {})
        },
        async sure() {
            if (this.currentPage == "1") {
                this.$refs.FormRef.validate(valid => {
                    if (valid) {
                        if (!/^.{6}$/.test(this.registerMobileFormData.enterVerificationCode)) {
                            this.enterVerificationCodeError = "请输入6位短信验证码"
                        } else {
                            if (this.checked) {
                                this.api
                                    .getListAPI(
                                        {
                                            phone: this.registerMobileFormData.phone,
                                            code: this.registerMobileFormData.enterVerificationCode,
                                        },
                                        "/unifyauth/sms/confirmCode"
                                    )
                                    .then(res => {
                                        if (res.data) {
                                            this.currentPage = "2"
                                        }
                                    })
                                    .catch(error => {})
                            } else {
                                this.$message({
                                    message: "请先同意服务协议和隐私政策",
                                    type: "warning",
                                })
                                this.checked = true
                            }
                        }
                    }
                })
            } else if (this.currentPage == "2") {
                this.PasswordFormRefFrom = await this.$refs.PasswordFormRef.validate()
                if (this.PasswordFormRefFrom) {
                    this.currentPage = "3"
                }
            } else if (this.currentPage == "3") {
                this.$refs.fillInInformationFormRef.validate(async valid => {
                    if (valid) {
                        await this.api
                            .postFormAPI(
                                {
                                    mobileNumber: this.registerMobileFormData.phone, //手机号码
                                    memberName: this.fillInInformationFormData.memberName, //姓名
                                    companyName: this.fillInInformationFormData.companyName, //企业或组织名称
                                    companyAlias: this.fillInInformationFormData.companyAlias, //企业或组织简称
                                    companySize: this.fillInInformationFormData.companySize, //企业或组织规模
                                    position: this.fillInInformationFormData.position, //职位
                                    companyLocation: this.fillInInformationFormData.companyLocation.join("/"), //所在地区
                                    password: this.Rsa.encrypt(this.PasswordFormRefFrom.password), //密码
                                    invitationCode: this.PasswordFormRefFrom.invitationCode, //邀请码
                                    confirmPassword: this.Rsa.encrypt(this.PasswordFormRefFrom.confirmPassword),
                                },
                                "/unifyauth/tenant/register",
                                "",
                                {
                                    showSuccessMessage: true,
                                    successMessage: "注册成功!",
                                }
                            )
                            .then(() => {
                                this.goLogin()
                            })
                            .catch(error => {
                                console.log("error", error)
                            })
                            .finally(() => {})
                    }
                })
            }
        },
        handleEnterVerificationCodeFocus() {
            this.enterVerificationCodeError = ""
        },
        async handleSend() {
            let phoneErrorMessage = await new Promise(resolve => {
                this.$refs.FormRef.validateField("phone", errorMessage => {
                    console.log("validateField.phone", errorMessage)
                    resolve(!!errorMessage)
                })
            })
            console.log(phoneErrorMessage, "phoneErrorMessage")

            if (phoneErrorMessage) return
            this.setLoading()
            try {
                await this.checkPhone(this.registerMobileFormData.phone)
                await this.sendVerificationCode(this.registerMobileFormData.phone)
                this.sendCodeTimeStamp = Date.now()
            } catch (error) {}
            this.closeLoading()
        },
        goLogin() {
            this.$router.push({ name: "Index" })
            this.currentPage = "1"
        },
        closeLoading() {
            this.loading = false
            this.loadingInstance && this.loadingInstance.close()
        },
        setLoading() {
            this.loading = true
            this.loadingInstance = this.$loading({
                background: "transparent",
                spinner: "",
            })
        },
        checkPhone(phone) {
            return new Promise((resolve, reject) => {
                this.api
                    .getListAPI(
                        {
                            phone,
                        },
                        "/unifyauth/tenant/checkPhone"
                    )
                    .then(() => {
                        resolve(true)
                    })
                    .catch(error => {
                        console.log("checkPhone.error", error)
                        reject(error)
                    })
            })
        },
        sendVerificationCode(phone) {
            return new Promise((resolve, reject) => {
                this.api
                    .getListAPI(
                        {
                            phone,
                        },
                        "/unifyauth/sms/send",
                        {
                            showSuccessMessage: true,
                            successMessage: "发送成功",
                        }
                    )
                    .then(() => {
                        resolve(true)
                        this.sendCodeTimeStamp = Date.now()
                    })
                    .catch(error => {
                        reject(error)
                        console.log("sendVerificationCode.error", error)
                    })
            })
        },
    },
    computed: {},
}
</script>

<style lang="less" scoped>
#MRegister {
    height: 94%;

    & > .register-mobile {
        height: 100%;
        border: 1px solid #999;
        border-radius: 10px;
        padding: 10px;
        margin: 10px;

        & > .register-mobile-foot {
            position: fixed;
            left: 50%;
            transform: translateX(-50%);
            bottom: 55px;
            width: 260px;
            text-align: center;

            & > .el-button--primary {
                margin-top: 10px;
                width: 200px;
            }
        }
    }
    & > .set-register-password {
        height: 100%;
        border: 1px solid #999;
        border-radius: 10px;
        padding: 10px;
        margin: 10px;
        & > .PasswordForm {
            margin-top: 20px;
        }
        & > .register-mobile-foot {
            position: fixed;
            left: 50%;
            transform: translateX(-50%);
            bottom: 55px;
            width: 260px;
            text-align: center;

            & > .el-button--primary {
                margin-top: 10px;
                width: 200px;
            }
        }
    }
    & > .fill-in-information {
        height: 100%;
        border: 1px solid #999;
        border-radius: 10px;
        padding: 10px;
        margin: 10px;
        & > .fill-in-information--title {
            margin-top: 10px;
        }
        & > .register-mobile-foot {
            position: fixed;
            left: 50%;
            transform: translateX(-50%);
            bottom: 55px;
            width: 260px;
            text-align: center;

            & > .el-button--primary {
                margin-top: 10px;
                width: 200px;
            }
        }
    }
}
</style>