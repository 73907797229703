<template>
    <div class="common-service-privacy">
        <el-checkbox v-model="innerChecked">我已阅读并同意</el-checkbox>
        <span>服务协议</span>
        和
        <span>隐私政策</span>
    </div>
</template>

<script>
export default {
    model: {
        prop: 'checked',
        event: 'input'
    },
    props: {
        checked: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
        };
    },
    computed: {
        innerChecked: {
            get(){
                return this.checked;
            },
            set(val){
                // console.log('service-privacy.set', val);
                this.$emit('input', val);
            }
        }
    },
    methods: {
        validate(){
            return new Promise((resolve, reject) => {
                if (!this.innerChecked){
                    this.$message({
                        message: "请先同意服务协议和隐私政策",
                        type: "warning",
                    });
                    reject(this.innerChecked);
                    this.$emit('input', true);
                } else {
                    resolve(this.innerChecked);
                }
            })
        }
    }
};
</script>

<style lang="less">
.common-service-privacy{
    &>.el-checkbox{
        &>.el-checkbox__input{
            &.is-checked{
                & + .el-checkbox__label{
                    color: inherit;
                }
            }
        }
        &>.el-checkbox__label{
            font-size: 14px;
        }
        & ~span{
            color: #3370ff;
            cursor: pointer;
        }
    }
}
</style>
