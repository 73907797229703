const Dashboard = () => import('./Dashboard');

export default [
	{
		path: '/Dashboard',
		name: 'Dashboard',
		component: Dashboard,
		meta: {
			title: '仪表盘',
			groupName: '工作台',
			icon: 'Dashboard'
		}
	},
]

