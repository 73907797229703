//choiceProduct.js文件
// 选择产品  如组织结构-角色权限配置-权限配置
import router from "@/router";
import store from "@/store";
import choiceProduct from "./index.vue"
let $choiceProduct;
export default {
    install(Vue) {
        if (!$choiceProduct) {
            let $app = Vue.extend(choiceProduct);
            $choiceProduct = new $app({
                router,
                store,
            }).$mount(document.createElement("div"))
            // console.log('choiceProduct', $choiceProduct);
            
            document.body.appendChild($choiceProduct.$el)
        }
        let show = params => {
            // console.log('show', params, $choiceProduct);
            Object.keys(params).forEach(key => {
                // console.log('key', key, $choiceProduct[key]);
                
                $choiceProduct[key] = params[key]
            })
            $choiceProduct.visible = true
        };
        let $choiceProductFunction = show;
        $choiceProductFunction.show = show;
        $choiceProductFunction.hide = function(){
            $choiceProduct.visible = false
        };
        Vue.prototype.$choiceProduct = $choiceProductFunction;
    },
}
