import { getSortedRoutesByGroup } from './generate-routes';

/** 工作台 */
import Workbench from '@/views/Workbench';

/** 组织结构 */
import OrganizationStructure from '@/views/OrganizationStructure';

/** 订单管理 */
import OrderManagement from '@/views/OrderManagement';

/** 产品管理 */
import InvoicesManagement from '@/views/InvoicesManagement';

/** 企业设置 */
import EnterpriseSetup from '@/views/EnterpriseSetup';

const routes = [
	...Workbench,
	...OrganizationStructure,
	...OrderManagement,
	...InvoicesManagement,
	...EnterpriseSetup,
]

const menus = getSortedRoutesByGroup(routes.filter(item => {
	return typeof item.component === 'undefined' || (!(typeof item.meta === 'object' && item.meta && typeof item.meta.hideMenu === 'boolean' && item.meta.hideMenu))
}));

export default routes;

export {
	routes,
	menus
}