const MemberDepartment = () => import('./MemberDepartment');

const RoleManagement = () => import('./RoleManagement');

const RolePermission = () => import('./RolePermission');

const AccountManagement = () => import('./AccountManagement');

export default [
	{
		path: '/MemberDepartment',
		name: 'MemberDepartment',
		component: MemberDepartment,
		meta: {
			title: '成员与部门',
			groupName: '组织结构',
			icon: 'MemberDepartment'
		}
	},
	{
		path: '/AccountManagement',
		name: 'AccountManagement',
		component: AccountManagement,
		meta: {
			title: '账号管理',
			groupName: '组织结构',
			icon: 'AccountManagement'
		}
	},
	{
		path: '/RoleManagement',
		name: 'RoleManagement',
		component: RoleManagement,
		meta: {
			title: '角色管理',
			groupName: '组织结构',
			icon: 'RoleManagement'
		}
	},
	{
		path: '/RolePermission',
		name: 'RolePermission',
		component: RolePermission,
		meta: {
			title: '权限配置',
			groupName: '组织结构',
			icon: 'permissionConfiguration'
		}
	},
	
]

