/**
 * @param {{}[]} routes
*/
const getSortedRoutesByGroup = routes => {
	// console.log('getSortedRoutesByGroup-start', routes);
	let groupMenus = {};
	for (let i in routes){
		if (typeof routes[i].meta !== 'object' || !routes[i].meta || !routes[i].meta.groupName){
			continue;
		}
		if (typeof groupMenus[routes[i].meta.groupName] === 'undefined'){
			groupMenus[routes[i].meta.groupName] = [routes[i]];
		} else {
			groupMenus[routes[i].meta.groupName].push(routes[i]);
		}
	}
	// console.log('groupMenus.keys', Object.keys(groupMenus));
	
	return groupMenus;
}

export {
	getSortedRoutesByGroup
}