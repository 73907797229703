
const devServer = '';
// const devServer = 'http://47.104.68.37:13012';
// const newServer="http://118.178.184.218:13012"
// const oldServer = 'http://47.104.68.37:13012';
/** 生成地址 开发环境为/api代理  生产环境为本地localhost */
const ssoServer = process.env.NODE_ENV === "development" ? devServer : '';
export default {
    ssoServer,
}

export {
	/** 接口地址 */
    ssoServer,
}
