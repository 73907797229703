<template>
    <div id="MIndex">
        <div class="index-profile">
            <div class="index-description">
                <div class="description-title">试验检测一体化云服务</div>
                <div class="description-content">
                    <!-- 小乐云是一种基于云计算技术的综合性检测服务平台，结合先进的物联网技术、云计算技术和数据处理能力，将检测业务、检测资源、检测客户、质量体系等进行了深度整合。通过集中化、数字化的管理方式，提高检测工作的效率与质量，实现检测业务协同、检测数据共享与分析，降低企业运营成本，增强检测服务的灵活性和可扩展性，以满足日益增长的检测需求，推动行业的智能化发展。 -->
                    小乐云，是一款专为试验检测行业打造的垂直型SaaS平台，致力于科研院所、质检中心、计量中心等检测校准机构，提供全要素、全过程、全场景和全链条的All in One试验检测云服务。助力各行业各领域检测校准机构，快速提升检测效率和客户服务水平，降低差错率，提升检测透明化管理能力，支撑数字经济高质量发展。
                </div>
                <div class="description-version">免费试用</div>
            </div>
        </div>
        <div class="index-products">
            <div class="index-product" :ref="'Product' + index + 'Ref'" v-for="(item, index) in productList" :key="index">
                <div class="index-product-name">
                    <span>{{ item.productName }}</span>
                </div>
                <div class="index-product-description">
                    {{ item.productDescription }}
                </div>
                <!-- <div class="index-product-purchase" v-if="item.productUrl != '/'">
                    <span v-if="item.freeTrials == '01'" class="free-trials">免费试用</span>
                    <span class="index-product-purchase-text">
                        <span>立即购买</span>
                        <i class="el-icon-arrow-right"></i>
                    </span>
                </div>
                <div class="index-product-purchase" style="color: #666666" v-else>即将上线，敬请期待</div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "MIndex",

    data() {
        return {
            productList: [],
        }
    },

    mounted() {
        this.queryProduct()
    },

    methods: {
        queryProduct() {
            this.api
                .postFormAPIProduct(
                    {
                        pageNum: 1,
                        pageSize: 999999,
                        filters: [
                            {
                                fieldName: "productName",
                                operator: "LIKE",
                                fieldValue: "",
                                connectOperator: "OR",
                                groupName: "_s",
                            },
                            {
                                fieldName: "productDescription",
                                operator: "LIKE",
                                fieldValue: "",
                                connectOperator: "OR",
                                groupName: "_s",
                            },
                            {
                                fieldName: "versionName",
                                operator: "LIKE",
                                fieldValue: "",
                                connectOperator: "OR",
                                groupName: "_s",
                            },
                            {
                                fieldName: "discountsAndPromotions",
                                operator: "LIKE",
                                fieldValue: "",
                                connectOperator: "OR",
                                groupName: "_s",
                            },
                            {
                                fieldName: "freeTrials",
                                operator: "LIKE",
                                fieldValue: "",
                                connectOperator: "OR",
                                groupName: "_s",
                            },
                        ],
                        sorters: [
                            {
                                sortField: "productOrder",
                                direction: "ASC",
                            },
                            {
                                sortField: "createTime",
                                direction: "DESC",
                            },
                        ],
                    },
                    "queryProduct"
                )
                .then(res => {
                    this.productList = res.data.data.records || []
                })
                .catch(error => {
                    console.log("error", error)
                })
        },
    },
}
</script>
<style lang="less" scoped>
#MIndex {
    height: 100%;
    & > .index-profile {
        background-image: url("../Index//assets/images//profile-bg-image.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        display: flex;
        flex-flow: row nowrap;
        & > .index-description {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            padding: 10px;
            & > div {
                &.description-title {
                    font-size: 16px;
                }
                &.description-content {
                    margin: 6px 0 16px;
                    font-size: 14px;
                    font-weight: 548;
                }
                &.description-version {
                    background-color: rgb(45, 116, 244);
                    align-self: flex-start;
                    padding: 12px 30px;
                    color: #fff;
                    font-size: 16px;
                    letter-spacing: 1px;
                    cursor: pointer;
                }
            }
        }
    }
    & > .index-products {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 10px;
        padding: 10px;
        gap: 10px;
        & > .index-product {
            border: 1px solid rgb(213, 215, 220);
            padding: 10px;
            & > .index-product-name {
                display: flex;
                align-items: center;
                & > span {
                    &:first-child {
                        font-weight: 550;
                        font-size: 18px;
                    }
                }
            }
            & > .index-product-description {
                padding: 10px 0 10px;
                color: rgb(102, 102, 102);
                overflow: auto;
                height: 100px;
            }
            & > .index-product-purchase {
                position: relative;
                align-self: flex-start;
                color: rgb(45, 116, 244);
                cursor: pointer;
                display: flex;
                align-items: center;
                & > .free-trials {
                    padding: 1px 4px;
                    background-color: rgb(253, 238, 228);
                    color: rgb(255, 120, 25);
                    font-size: 12px;
                }
                & > .index-product-purchase-text {
                    position: absolute;
                    left: 80px;
                    width: 80px;
                }
                & > i {
                    margin: 0 0 0 4px;
                    font-size: 16px;
                    font-weight: bold;
                }
            }
        }
    }
}
</style>
